import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
// import { error } from 'console';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  jwtService:JwtHelperService;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) { 
    this.jwtService = new JwtHelperService();

  }

  public get(url: string): Observable<any> {
    const token = this.getCookie('access_token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Accept-Language': 'fr'
    });
    const options = { headers: headers }
    return this.http.get(environment.apiUrl + '/' + url, options).pipe(
      catchError(this.errorHandler)
    );
  }

  public post(url: string, sendParams: any): Observable<any> {
    // const token = "";
    const token = this.getCookie('access_token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Accept-Language': 'fr'
    });
    sendParams = (sendParams) ? sendParams : {};
    const options = { headers: headers }
    // console.log('options ', options)

    // this.redirectToRoute('login')
    // this.http.post(environment.apiUrl + '/' + url, sendParams, options).

    /*try {
      return this.http.post(environment.apiUrl + '/' + url, sendParams, options).pipe(
        catchError(this.errorHandler)      
      );
    } catch (error) {
      console.log('error ', error)
    }*/
    /*let result;
    let error;
    this.http.post(environment.apiUrl + '/' + url, sendParams, options).subscribe({
      next:(res:any)=>{
        // return res;
        result = res;
      },
      error:(err:any) => {
        result = 1;
        // this.redirectToRoute('login')
      }
    });
    if(result){
      return result;
    }*/
    // return;

    return this.http.post(environment.apiUrl + '/' + url, sendParams, options).pipe(
      catchError(this.errorHandler)      
    );
  }

  public put(url: string, sendParams: any): Observable<any> {
    // const token = "";
    const token = this.getCookie('access_token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Accept-Language': 'fr'
    });
    sendParams = (sendParams) ? sendParams : {};
    const options = { headers: headers }
    return this.http.put(environment.apiUrl + '/' + url, sendParams, options).pipe(
      catchError(this.errorHandler)
    );
  }

  public delete(url: string): Observable<any> {
    // const token = "";
    const token = this.getCookie('access_token');
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token
    });
    const options = { headers: headers }
    return this.http.delete(environment.apiUrl + '/' + url, options);
}

  errorHandler = (err: any): Observable<any> => {
    if (err.status === 401){
      this.redirectToRoute('login')
    }
    return err
  };

  async checkFileSize(file: File) {
    const reader = new FileReader();

    const result = await new Promise<{ width: number, height: number, size: number }>((resolve, reject) => {
      reader.onload = () => {
        const image: HTMLImageElement = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          const size = file.size;

          resolve({ width, height, size });
        };
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
    return result;
  }

  public getImageExtension(imageType: string): string {
    switch (imageType) {
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
      case 'image/gif':
        return '.gif';
      // add more cases as needed
      default:
        return '';
    }
  }

  async convertToBase64Image(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject('No file specified.');
      }
    });
  }
  
  convertDataUrlToBlob(dataUrl: any): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  generateUniqueId(): string {
    const values = new Uint8Array(8); // 8 bytes = 64 bits
    crypto.getRandomValues(values);
    let result = '';
    for (let i = 0; i < values.length; i++) {
      result += values[i].toString(16).padStart(2, '0');
    }
    return result.slice(0, 13);
  }
  
  redirectToRoute(route: string) {
    this.router.navigateByUrl(route);
  }

  redirectToPackPreview(route: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    window.open(url, '_blank');
  }

    //Image format getting function
    getImageDimensions(url: string) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          const dimensions = {
            width: img.width,
            height: img.height
          };
          resolve(dimensions);
        };
        img.onerror = reject;
      });
    }

    setCookie(name: string, value: string | number | boolean, days: number) {
      var expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);
    
      var cookieValue = value + '; expires=' + expirationDate.toUTCString() + '; path=/';
      document.cookie = name + '=' + cookieValue;
    }

    getCookie(name: string | any[]) {
      var cookieString = document.cookie;
      var cookies = cookieString.split(';');
    
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
    
        // Check if the cookie starts with the desired name
        if (cookie.startsWith(name + '=')) {
          // Extract and return the cookie value
          return cookie.substring(name.length + 1);
        }
      }
    
      // Cookie not found
      return null;
    }

    deleteCookie(cookieName: string) {
      document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    public logout(): void {
      this.deleteCookie('email');
      this.deleteCookie('id_agency');
      this.deleteCookie('id_advertiser');
      this.deleteCookie('name');
      this.deleteCookie('access_token');
      window.location.href = environment.baseUrl;     
    }

    decodeAccessToken(){
      let access_token = this.getCookie('access_token') ?? '';
      return this.jwtService.decodeToken(access_token)
    }
  
    number_format(number: {
        toLocaleString: (arg0: string, arg1: {
          useGrouping: boolean; // Enable thousands separator
          minimumFractionDigits: number; maximumFractionDigits: number;
        }) => any;
      }){
      const formattedNumber = number.toLocaleString('fr-FR', {
        useGrouping: true,  // Enable thousands separator
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formattedNumber
    }

    number_format_new(number: any){
      number = Number(number);
      if (isNaN(number)) {
        return "-";
      }
    const formattedNumber = number.toLocaleString('fr-FR', {
      useGrouping: true,  // Enable thousands separator
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber
  }

  number_format_three_decimal(number: {
    toLocaleString: (arg0: string, arg1: {
      useGrouping: boolean; // Enable thousands separator
      minimumFractionDigits: number; maximumFractionDigits: number;
    }) => any;
  }){
    number = Number(number);
    const formattedNumber = number.toLocaleString('fr-FR', {
      useGrouping: true,  // Enable thousands separator
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
    return formattedNumber
  }

  number_format_three_decimal_new(number: any){
    number = Number(number);
    if (isNaN(number)) {
      return "-";
    }
    const formattedNumber = number.toLocaleString('fr-FR', {
      useGrouping: true,  // Enable thousands separator
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
    // return `${formattedNumber} €`
    return `${ this.remove_trailing_zeros(formattedNumber)} €`
  }

  number_format_two_decimal_new(number: any){
    number = Number(number);
    if (isNaN(number)) {
      return "-";
    }
    const formattedNumber = number.toLocaleString('fr-FR', {
      useGrouping: true,  // Enable thousands separator
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${this.remove_trailing_zeros(formattedNumber)} €`
  }

  remove_trailing_zeros(formattedNumber:string){
    const parts = formattedNumber.split(',');
    
    if (parts.length > 1 && (parts[parts.length - 1] === '000' || parts[parts.length - 1] === '00')) {// If the number ends with multiple zeros, remove them
      parts.pop(); // Remove last part ('000') or ('00')
    }
    
    formattedNumber = parts.join(',');// Join the parts back together with commas
    return formattedNumber;
  }

    number_space_format(number: any){
    number = Number(number);
    if (isNaN(number)) {
      return "-";
    }
    const formattedNumber = number.toLocaleString('fr-FR', {
      useGrouping: true,  // Enable thousands separator
    });    
    return formattedNumber
  }
    validateYoutubeUrl(url: any) {
      // Define a regular expression pattern for a YouTube video URL
      const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=.+/;

      // Test if the URL matches the YouTube URL pattern
      if (youtubePattern.test(url)) {
        // URL is a YouTube video URL
        return true;
      } else {
        // URL is not a YouTube video URL
        return false;
      }
  }

  isURLSecure(url:any) {
    // Use a regular expression to check if the URL starts with "https"
    const regex = /^https:\/\//i;
    return regex.test(url);
  }

  isValidUrl(url: string): boolean {
    // Define the regular expression for https://www URLs
    const urlPattern: RegExp = /^https:\/\/www\..*/;
  
    // Check if the given URL matches the pattern
    return urlPattern.test(url);
  }

  isVideoUrl(url: string): boolean {
    const videoPattern = /\.(mp4|avi|mov|mkv|wmv|flv|webm)$/i;
    if (videoPattern.test(url)) {
      // URL is a YouTube video URL
      return true;
    } else {
      // URL is not a YouTube video URL
      return false;
    }
  }

  convertISO8601ToHMS(duration: string): any {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);   
    return match;
  }

  youtube_parser(url: string){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

  getDateDifference(startDate: string, endDate: string): number {
    // Convert DD/MM/YYYY to a valid date format YYYY-MM-DD
    const start = this.parseDate(startDate);
    const end = this.parseDate(endDate);

    // Get the time difference in milliseconds
    const diffInMs = Math.abs(end.getTime() - start.getTime());

    // Convert time difference to days
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) + 1;

    return diffInDays;
  }

  // Helper function to parse DD/MM/YYYY into a valid Date object
  private parseDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('/').map(Number);
    // Create the date using the format: new Date(year, monthIndex, day)
    return new Date(year, month - 1, day); // month is 0-based
  }

}
